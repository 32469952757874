import { useState } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../components/navbar/Navbar";
import Modal from "../components/modal/Modal";
// import FirstSection from "../components/instantConversionComp/FirstSection";
// import SecondSection from "../components/instantConversionComp/SecondSection";
// import ThirdSection from "../components/instantConversionComp/ThirdSection";
import Footer from "../components/footer/Footer";
import FirstSection from "../components/bankDirect/FirstSection";
import SecondSection from "../components/bankDirect/SecondSection";
import SeventhSection from "../components/bankDirect/SeventhSection";
import SixthSection from "../components/bankDirect/SixthSection";
import FifthSection from "../components/bankDirect/FifthSection";
import ThirdSection from "../components/bankDirect/ThirdSection";
import FourthSection from "../components/bankDirect/FourthSection";

const BankDirect = () => {
  const [isOpen, setIsOpen] = useState(false);


  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Helmet>
      <title>Bank Direct</title>
      <meta
          name="title"
          content=" 
          Bank Direct by Crane: Instant crypto to naira conversion"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="Bank Direct by Crane: Instant crypto to naira conversion"
          data-react-helmet="true"
        />
        <link rel="canonical" href="https://usecrane.co/instant-conversion" />
        <meta
          name="description"
          content="Bank Direct, crypto to local currency, international payments, instant crypto to naira conversion, crypto payments, convert crypto to naira"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="Bank Direct, crypto to local currency, international payments, instant crypto to naira conversion, crypto payments, convert crypto to naira"
          data-react-helmet="true"
        />
         <meta
          name="keywords"
          content="Bank Direct, crypto to local currency, international payments, instant crypto to naira conversion, crypto payments, convert crypto to naira"
          data-react-helmet="true"
        />
     
      </Helmet>
      <Navbar
        bgColor="[#FFF]"
        textColor="[#050B05]"
        openModal={openModal}
  
      />
      <Modal onClose={closeModal} isOpen={isOpen} />
      <FirstSection />
      <SecondSection/>
      <ThirdSection/>
      <FourthSection/>
      <FifthSection/>
      <SixthSection/>
      <SeventhSection/>
      <Footer />
    </>
  );
};

export default BankDirect;
